var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container",staticStyle:{"background-color":"#efeff1"}},[_c('list-toolbar',{attrs:{"with-pagination":true,"list-query":_vm.listQuery,"total":_vm.total,"has-selected":false,"title":'Συνεργάτες Bee real estate'},on:{"value-changed":_vm.onSearchChanged,"pagination":_vm.getList}},[_c('template',{slot:"actions"},[_c('el-button',{attrs:{"icon":"el-icon-s-operation"},on:{"click":function($event){return _vm.handleCommand('filters')}}},[_vm._v(" "+_vm._s(_vm.$t('userList.filters'))+" ")])],1)],2),_c('div',{staticClass:"container-fluid"},[_c('el-row',{directives:[{name:"loading",rawName:"v-loading.lock",value:(_vm.listLoading),expression:"listLoading",modifiers:{"lock":true}}],staticClass:"bee-main-container",attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":24}},[_c('el-table',{staticClass:"bee-style-table full-width",attrs:{"data":_vm.list,"border":"","fit":"","highlight-current-row":""},on:{"sort-change":_vm.handleSortChange}},[_c('el-table-column',{attrs:{"align":"center","prop":"code","sortable":"custom","label":_vm.$t('table.code')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.code))])]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"translations.first_name,translations.last_name","sortable":"custom","label":_vm.$t('table.fullName')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.getTranslations(row)))])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('table.office'),"prop":"office.name","sortable":"custom"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.getTranslations(row.office, ['name'])))])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('table.email'),"prop":"main_email","sortable":"custom"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.main_email))])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('table.mobilePhone'),"prop":"phone","sortable":"custom"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.phone))])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('table.role')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s((row.roles || []).map(function (role) { return _vm.$t('roles.' + role.name.toLowerCase()); }).join(', ')))])]}}])})],1)],1)],1),_c('pagination',{attrs:{"hide-on-single-page":true,"total":_vm.total,"page":_vm.listQuery.query.pagination.page,"limit":_vm.listQuery.query.pagination.page_size},on:{"update:page":function($event){return _vm.$set(_vm.listQuery.query.pagination, "page", $event)},"update:limit":function($event){return _vm.$set(_vm.listQuery.query.pagination, "page_size", $event)},"pagination":_vm.getList}})],1),_c('FiltersDrawer',{attrs:{"filters":_vm.filters,"count":_vm.count,"show":_vm.showFilters,"size":30,"cols-per-row":1},on:{"filters-changed":_vm.filtersChanged,"submit-filters":_vm.submitFilters,"close":function($event){_vm.showFilters = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }