
































import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'SupportList'
})

export default class extends Vue {
  private baseUrl = 'https://www.youtube-nocookie.com/embed/'
  private videos = [
    '35SG9zmO_ZI',
    'OPg4drf9mdg',
    '5ZFWOj4vnnQ'
  ]
}
