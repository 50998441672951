



























import { Component, Vue } from 'vue-property-decorator'
import { getProp } from '@/api/props'
import { getLocale } from '@/lang'

@Component({
  name: 'SupportList'
})
/*
 Category Format
 {
    "categories": [
        {
            "name": "1",
            "videos": [
                {
                    "name": "1",
                    "url": ""
                },
                {
                    "name": "2",
                    "url": ""
                },
                {
                    "name": "3",
                    "url": ""
                },
                {
                    "name": "4",
                    "url": ""
                }
            ]
        }
    ]
}
 */
export default class extends Vue {
  private educationalMaterial: any = {}
  created() {
    this.getEducationalMaterial()
  }

  private async getEducationalMaterial() {
    try {
      const { data } = await getProp({ key: 'educational_material', locale: getLocale() })
      this.educationalMaterial = data
      console.log(this.educationalMaterial)
    } catch (err) {}
  }
}
