






















































































































import { Component, Vue } from 'vue-property-decorator'
import {
  getAllUsers,
  getAllUsersCount
} from '@/api/users'
import {
  CollectionRequestMeta,
  createFilter,
  getSortItems, ignoreCaseSearchField,
  prepareListFilters,
  rolesFilter,
  searchFieldConcat, searchFieldConcatReverse
} from '@/utils/filter'
import Pagination from '@/components/Pagination/index.vue'
import ListToolbar from '@/components/ListToolbar/index.vue'
import FiltersDrawer from '@/components/Filter/FiltersDrawer.vue'
import { debounce, getTranslations } from '@/utils'
import { AppModule, DeviceType } from '@/store/modules/app'
import i18n from '@/lang'
import { getOffices } from '@/api/offices'

@Component({
  name: 'UserList',
  components: {
    Pagination,
    ListToolbar,
    FiltersDrawer
  }
})

export default class extends Vue {
  private count = 0
  private total = 0
  private list = []
  private listLoading = true
  private showFilters = false
  private search = ''
  private listQuery: CollectionRequestMeta = createFilter()
  private debounceMethod = debounce(this.getList)
  private getTranslations = getTranslations
  private searchFields = [
    searchFieldConcat(),
    ignoreCaseSearchField('office.name'),
    searchFieldConcatReverse(),
    { key: 'code' },
    { key: 'mainEmail' },
    { key: 'phone' }
  ]

  private filters: any = [
    rolesFilter(),
    {
      component: 'MultiSelectFilter',
      modelValue: [],
      key: 'office.id',
      label: i18n.t('filters.offices'),
      itemText: ['name'],
      searchFields: [{
        key: 'code'
      }],
      apiEndpoint: getOffices
    }
  ]

  created() {
    this.getList()
  }

  get isMobile() {
    return AppModule.device === DeviceType.Mobile
  }

  private handleSortChange(params: any = {}) {
    if (this.listQuery?.query?.sort) {
      this.listQuery.query.sort = getSortItems(params)
    }
    this.getList()
  }

  private prepareFilters(filters: any) {
    prepareListFilters({
      listQuery: this.listQuery,
      withDefaultSort: true,
      search: this.search,
      searchFields: this.searchFields,
      filters: filters
    })
  }

  private async filtersChanged(filters: any) {
    try {
      this.prepareFilters(filters)
      const { data } = await getAllUsersCount(this.listQuery)
      this.count = data || 0
    } catch (err) {}
  }

  private submitFilters(filters: any) {
    this.filters = filters
    this.restartPaging()
    this.getList()
  }

  private handleCommand(command: string) {
    switch (command) {
      case 'filters': {
        this.count = this.total
        this.showFilters = true
        break
      }
    }
  }

  private restartPaging() {
    if (this.listQuery.query?.pagination) {
      this.listQuery.query.pagination.page = 1
    }
  }

  private async getList() {
    this.listLoading = true
    try {
      this.prepareFilters(this.filters)
      const { data } = await getAllUsers(this.listQuery)
      this.list = data.collection
      this.total = data.pagination.total_items || 0
      this.count = data.pagination.total_items || 0
    } catch (err) {}
    this.listLoading = false
  }

  onSearchChanged(search: string) {
    this.search = search
    this.restartPaging()
    this.debounceMethod()
  }
}
